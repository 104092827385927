<template>
  <LoadingLayout></LoadingLayout>
</template>

<script>
/* eslint-disable max-len */
import LoadingLayout from "@/layouts/LoadingLayout.vue";

export default {
  name: "login",
  components: {
    LoadingLayout
  },
  created() {
    if (this.$store.getters["user/isAuthenticated"]) {
      if (this.loginRedirect) {
        this.$router.push({ path: this.loginRedirect }).catch(() => {});
        this.$store.dispatch("views/clearLoginRedirect");
      } else {
        this.$router.push("/register").catch(() => {});
      }
    } else {
      const m =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://nrl-qa.nrl.digital/"
          : "https://www.nrl.com/";
      const clientId = process.env.VUE_APP_PIN_ENV === "test" ? "grassrootstest" : "grassroots";
      const redirectUri = this.$store.getters["root/redirectUri"];
      const url = `/account/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid email profile offline_access verified&response_type=code`;
      const a = new URL(url, m);
      window.location = a;
    }
  },
  computed: {
    loginRedirect() {
      return this.$store.getters["views/loginRedirect"];
    }
  }
};
</script>
